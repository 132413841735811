import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const productsIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All products." />
        <div className="container container-max-md">
          <Bio />
          <p className="mt-5">
            No products found.
          </p>
        </div>
      </Layout >
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All products." />
      <div className="container container-max-md">
        <h1 className="visually-hidden">All products. | Onur Boz</h1>
        <ol className="list-unstyled">
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                  lang={post.frontmatter.lang}
                >
                  <header>
                    <h2 className="h5 d-flex align-items-center flex-wrap">
                      <Link to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                      <div className="py-1 w-100">
                        <small className="text-muted mx-2">—</small>
                        <small className="text-muted"
                          dangerouslySetInnerHTML={{
                            __html: post.frontmatter.description || post.excerpt,
                          }}
                        />
                      </div>
                    </h2>
                    <small className="visually-hidden"
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </header>
                </article>
              </li>
            )
          })}
        </ol>
      </div>
    </Layout>
  )
}

export default productsIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {fileAbsolutePath: {regex: "/(\/content\/products)/.*.md$/"}}
      ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          lang
          type
        }
      }
    }
  }
`
